body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: white !important;
  overflow: hidden;
}

.rc-slider-handle {
  border: solid 2px var(--unnamed-color-137d3f) !important;
}

.rc-slider-handle:focus {
  background-color: var(--unnamed-color-137d3f) !important;
}

.rc-slider-track {
  background-color: var(--unnamed-color-94c4a8) !important;
}

.rc-slider-dot-active {
  border-color: var(--unnamed-color-94c4a8) !important;
}

.MuiToolbar-regular {
  min-height: unset !important;
}

.MuiTypography-h5 {
  font-family: var(--unnamed-font-family-aktivgrotesk-bold);
  font-size: var(--unnamed-font-size-16);
}

.MuiTypography-body1 {
  font-family: var(--unnamed-font-family-aktivgrotesk-bold);
  font-size: var(--unnamed-font-size-16);
  font-weight: bold;
  color: white;
}

.MuiTypography-body2 {
  font-family: var(--unnamed-font-family-space-mono);
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.MuiCollapse-wrapper {
  max-height: 100%;
}

#panel1d-content {
  height: 100%;
}
#panel2d-content {
  height: 100%;
}
#panel3d-content {
  height: 100%;
}
#panel4d-content {
  height: 100%;
}

:root {
  /* Colors: */
  --unnamed-color-ffffff: #ffffff;
  --unnamed-color-137d3f: #137d3f;
  --unnamed-color-e7f0eb: #e7f0eb;
  --unnamed-color-94c4a8: #94c4a8;
  /* --drop-target-bg: #e7f0eb80;*/
  --drop-target-bg: rgba(225, 233, 228, 0.8);
  --drop-target-bad-bg: rgba(255, 0, 0, 0.5);
  --super-light-green: rgb(228, 236, 232);
  --light-green: rgb(231, 240, 235);
  --red: rgb(223, 36, 46);

  /* Font/text values */
  --unnamed-font-family-space-mono: Space Mono;
  --unnamed-font-family-aktivgrotesk-regular: 'aktiv-grotesk';
  /*AktivGrotesk-Regular;*/
  --unnamed-font-family-aktivgrotesk-bold: 'aktiv-grotesk';
  /*AktivGrotesk-Bold;*/
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-bold: bold;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-12: 12px;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-16: 16px;
  --unnamed-font-size-22: 22px;
  --unnamed-font-size-25: 25px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-character-spacing-0-4: 0.4px;
  --unnamed-character-spacing-0-3: 0.3px;
  --unnamed-line-spacing-16: 16px;
  --unnamed-line-spacing-17: 17px;
  --unnamed-line-spacing-22: 22px;
  --unnamed-line-spacing-33: 33px;
  --unnamed-line-spacing-37: 37px;
  --unnamed-decoration-underline: underline;
}

hr.heading {
  height: 1px;
  background-color: var(--unnamed-color-137d3f);
  border: none;
}

/* Character Styles */
.heading-character-style {
  font-family: var(--unnamed-font-family-space-mono);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-22);
  line-height: var(--unnamed-line-spacing-33);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-137d3f);
}

.heading-character-style a {
  color: var(--unnamed-color-137d3f);
  text-decoration: none;
}

.heading-character-style a:hover {
  text-decoration: underline;
}

.heading-2-character-style a {
  color: var(--unnamed-color-137d3f);
  text-decoration: none;
}

.heading-2-character-style a:hover {
  text-decoration: underline;
}

.MuiCardHeader-subheader a {
  color: var(--unnamed-color-137d3f);
  text-decoration: underline;
}

.hash-tag-link {
  color: var(--unnamed-color-137d3f);
  text-decoration: underline;
  background-color: var(--super-light-green);
}

.hash-tag-link:hover {
  background-color: var(--unnamed-color-94c4a8);
}

.subtext-character-style {
  font-family: var(--unnamed-font-family-space-mono);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-12);
  line-height: var(--unnamed-line-spacing-17);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-137d3f);
}

.subtext-character-style a {
  color: var(--unnamed-color-137d3f);
  text-decoration: none;
}

.subtext-character-style a:hover {
  text-decoration: underline;
}

.unnamed-character-style-2 {
  font-family: var(--unnamed-font-family-space-mono);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-25);
  line-height: var(--unnamed-line-spacing-37);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-137d3f);
}

.code-character-style {
  font-family: var(--unnamed-font-family-space-mono);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-12);
  line-height: var(--unnamed-line-spacing-17);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-137d3f);
}

.main-character-style {
  font-family: var(--unnamed-font-family-aktivgrotesk-regular);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-22);
  letter-spacing: var(--unnamed-character-spacing-0-4);
  color: var(--unnamed-color-137d3f);
}

.control-character-style {
  font-family: var(--unnamed-font-family-aktivgrotesk-bold);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-16);
  letter-spacing: var(--unnamed-character-spacing-0-3);
  color: var(--unnamed-color-137d3f);
  text-decoration: var(--unnamed-decoration-underline);
}

.unnamed-character-style-6 {
  font-family: var(--unnamed-font-family-aktivgrotesk-bold);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-12);
  line-height: var(--unnamed-line-spacing-16);
  letter-spacing: var(--unnamed-character-spacing-0-3);
  color: var(--unnamed-color-137d3f);
}

.heading-1-character-style {
  font-family: var(--unnamed-font-family-aktivgrotesk-bold);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-25);
  line-height: var(--unnamed-line-spacing-22);
  letter-spacing: var(--unnamed-character-spacing-0-4);
  color: var(--unnamed-color-137d3f);
}

.heading-2-character-style {
  font-family: var(--unnamed-font-family-aktivgrotesk-bold);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-22);
  letter-spacing: var(--unnamed-character-spacing-0-4);
  color: var(--unnamed-color-137d3f);
}

.rounded-box {
  border-radius: 25px;
  border: 2px solid var(--unnamed-color-137d3f);
  padding-left: 10px;
  height: 30px;
}

.youtubeThumbnail {
  overflow: hidden;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 0;
  padding-top: 56.25%;
}

.vitThumbnail {
  overflow: hidden;
  background-color: black;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 0;
  padding-top: 56.25%;
}

.vitTitle a,
.youtubeTitle a {
  color: black;
  text-decoration: none;
}

.vitTitle a:hover,
.youtubeTitle a:hover {
  text-decoration: underline;
}

.item {
  -webkit-animation: fadein 0.3s linear forwards;
  animation: fadein 0.3s linear forwards;
}

.item-fadeout {
  -webkit-animation: fadeout 0.3s linear forwards;
  animation: fadeout 0.3s linear forwards;
}

.item-fadein {
  -webkit-animation: fadein 0.3s linear forwards;
  animation: fadein 0.3s linear forwards;
}

.item-show {
  opacity: 1;
}

.item-hide {
  opacity: 0;
}

.show-on-hover {
  opacity: 0;
}

.show-on-hover:hover {
  opacity: 1;
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

textarea:focus,
input:focus {
  outline: none;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  margin-top: 100px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.overlay-button {
  opacity: 0;
  transition: 0.5s ease;
}

.overlay-container:hover .overlay-button {
  opacity: 1;
}
