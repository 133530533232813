.timeline_content {
  position: relative;
  width: 100%;
  height: 100%;
}

.timeline_content > div {
  position: absolute;
  width: 143px;
  height: 80px;
  transform-origin: 50% 50% 0px;
  border-radius: 5px;

  line-height: 40px;
  font-size: 14.5px;

  touch-action: none;
}
