:root {
  --primary-dark: #137d40;
  --primary-light: #e7f0eb;
}

.App {
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.st0 {
  fill: none;
  stroke: var(--primary-dark);
  stroke-width: 12;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.zoom-in {
  cursor: zoom-in;
}

.zoom-out {
  cursor: zoom-out;
}

.zoomed {
  cursor: default;
}

.zoom-change {
  cursor: pointer;
}

.card-background {
  position: absolute;
  top: 18%;
  left: 18.9%;
  width: 62.2%;
  height: 75%;
  background-color: var(--drop-target-bg);
}

.card-background-small {
  position: absolute;
  top: 17%;
  left: 17.9%;
  width: 64.2%;
  height: 80%;
  background-color: var(--drop-target-bg);
  clip-path: polygon(0% 0%, 85% 0%, 100% 20%, 100% 100%, 0% 100%);
}

.node,
.node-placeholder,
.node-attach {
  width: 100%;
  height: 100%;
  font-size: 50pt;
  transform: translate(0, 0%);
}

.node {
  background-color: clear;
}

.node-placeholder {
  background-color: #dddddd;
}

.node-attach {
  background-color: #dddddd;
  font-size: 150%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.node-thumbnail {
  pointer-events: none;
  max-width: 100%;
  overflow: hidden;
}

.node-thumbnail-img-container {
  position: relative;
}

.node-thumbnail-img {
  width: 100%;
}

.node-thumbnail-img-clipped {
  clip-path: polygon(0% 0%, 85% 0%, 100% 26%, 100% 100%, 0% 100%);
  transition: clip-path 0.5s;
}

.node-thumbnail-img-noclip {
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 100% 100%, 0% 100%);
  transition: clip-path 0.5s;
}

.MuiIconButton-colorPrimary {
  color: var(--primary-dark) !important;
}

.node-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.node-overlay-icon {
  color: var(--primary-dark);
  width: 25%;
  height: 44%;

  background-color: #e7f0eb20;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 225%;
}

.node-overlay-icon:hover {
}

.node-overlay-icon-hover {
  cursor: pointer;
  background-color: #e7f0eb80;
}

.info {
  width: 100%;
  height: 100%;
}

.info-box {
  position: absolute;
  top: 3%;
  height: 60%;
  width: 100%;

  transform: translate(0, 0%);
}

.info-box-title-text-small {
  font: normal normal bold 1vw Courier;
  line-height: 1.4vw;
  color: var(--primary-dark);
}

.info-box-title-text {
  font: normal normal bold 1vw Courier;
  line-height: 1.4vw;
  color: var(--primary-dark);
}

.info-box-title {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 70%;
}

.info-box-subheader {
  position: absolute;
  top: 65%;
  left: 0%;
  width: 100%;
  height: 25%;

  font: normal normal normal 0.75vw Courier;
  color: var(--primary-dark);
}

.info-box-time {
  position: absolute;
  top: 5.5%;
  left: 0%;
  width: 100%;
  height: 25%;

  text-align: right;
  font: normal normal bold 0.85vw Courier;
  color: var(--primary-dark);
}

.info-box-icons {
  position: absolute;
  top: 25%;
  left: 0%;
  width: 100%;
  height: 75%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  color: grey !important;

  cursor: pointer;

  font-size: 130%;
}

.info-box-overlay-padding {
  width: 2%;
}

.info-overlay-text {
  position: absolute;
  left: 7.5%;
  top: 7.5%;
  width: 85%;
  height: 70%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.info-overlay-icons {
  position: absolute;
  left: 0%;
  top: 80%;
  width: 100%;
  height: 20%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: grey !important;
  font-size: 130%;
}

.info-overlay-icons svg {
  cursor: pointer;
}

.info-overlay-small-icons {
  position: absolute;
  left: 0%;
  top: 80%;
  width: 100%;
  height: 20%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: grey !important;
  font-size: 130%;
}

.info-overlay-small-icons svg {
  cursor: pointer;
}

.info-box-user-top-level a {
  font: oblique normal bold 1.2vw Courier;
  text-decoration: underline;
  color: var(--primary-dark);
  cursor: pointer;
}

.info-box-user a {
  font: oblique normal bold 0.75vw Courier;
  text-decoration: underline;
  color: var(--primary-dark);
  cursor: pointer;
}

.info-box-icons-padding {
  width: 10%;
}

.info-box-left {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 80%;
  height: 100%;
}

.info-box-right {
  position: absolute;
  top: 0%;
  left: 80%;
  width: 20%;
  height: 100%;
}

.max-lines-sm {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

/* CEQUINNE */
.max-lines-lg {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.info-overlay-toplevel {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
}

.info-overlay-toplevel-time {
  font: normal normal bold 1.52vw Courier;
  color: var(--primary-dark);

  position: absolute;
  left: 60%;
  top: 0%;
  width: 40%;
  height: 30%;

  text-align: right;
}

.info-overlay-toplevel-icons {
  font: normal normal bold 1.75vw Courier;
  color: grey !important;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  position: absolute;
  right: 0%;
  bottom: 0%;
  width: 17%;
  height: 60%;

  font-size: 250%;

  cursor: pointer;
}

.info-overlay-toplevel-subheader {
  font: normal normal normal 1.3vw Courier;
  color: var(--primary-dark);

  position: absolute;
  left: 0%;
  bottom: 0%;
  width: 75%;
  height: 33%;
}

.info-overlay-toplevel-text {
  font: normal normal bold 1.82vw Courier;
  color: var(--primary-dark);

  position: absolute;
  left: 0%;
  top: 0%;
  width: 60%;
  height: 66%;
}

/* CEQUINNE */
.info-overlay-small {
  position: absolute;
  left: 16.125%;
  top: 13%;
  width: 67.5%;
  height: 85%;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font: normal normal bold 3.5vw aktiv-grotesk;
  letter-spacing: 0.2vw;
  line-height: 3.7vw;
  color: var(--primary-dark);
}

.info-overlay-small-background {
  position: absolute;

  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;

  clip-path: polygon(0% 0%, 85% 0%, 100% 20%, 100% 100%, 0% 100%);

  background-color: var(--drop-target-bg);
}

.info-overlay-background {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;

  background-color: var(--drop-target-bg);
}

.info-overlay {
  position: absolute;
  left: 16%;
  top: 14%;
  width: 68%;
  height: 81.6%;

  font-size: 80pt;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;

  font: normal normal bold 3.5vw aktiv-grotesk;
  letter-spacing: 0.2vw;
  line-height: 3.7vw;

  color: var(--primary-dark);
}

.info-timeline {
  position: absolute;
  top: 3%;
  left: 0%;
  width: 100%;
  height: 14%;
}

.active-button {
  cursor: pointer;
  color: var(--primary-dark);
}

.inactive-button {
  color: var(--primary-dark);
  opacity: 0.5;
  cursor: default;
}

.info-timeline-left {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 2.5%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.info-timeline-right {
  position: absolute;
  top: 0%;
  left: 97.5%;
  width: 2.5%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.info-timeline-right-fullscreen {
  position: absolute;
  top: 0%;
  left: 97.5%;
  width: 2.5%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.info-timeline-scroll {
  position: absolute;
  top: 0%;
  left: 3%;
  width: 94%;
  height: 100%;

  overflow: hidden;
}

.info-control {
  position: absolute;
  top: 80%;
  left: 0%;
  height: 20%;
  width: 100%;
}

.timeline-grid {
  box-sizing: border-box;
  border-bottom: 1px solid var(--primary-dark);
  border-left: 1px solid var(--primary-dark);
}

.timeline-grid > span {
  border-top: 1px solid var(--primary-dark);
  border-right: 1px solid var(--primary-dark);
}

.timeline-node {
  cursor: pointer;
}

.timeline-node :hover {
  background-color: var(--primary-light);
}

.timeline-dot {
  position: absolute;
  top: 1px;
  height: calc(100% - 2px);
}

.timeline-dot > svg {
  display: block;
  width: auto;
  height: 100%;
  fill: var(--primary-dark);
}

.childNode {
  width: 100%;
  height: 100%;
  font-size: 100pt;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.segmented-control-size {
  font-size: 70%;
  padding-left: 2%;
  padding-top: 1%;
}
.segmented-control-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.segmented-control-option-small {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  color: var(--primary-dark);
  text-align: left;
  font: normal normal bold 1.2vw Courier;
  letter-spacing: 0px;

  cursor: pointer;
}

.segmented-control-option {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  color: var(--primary-dark);
  text-align: left;
  font: normal normal bold 1.2vw Courier;
  letter-spacing: 0px;

  cursor: pointer;
}

.segmented-control-selected {
  background-color: var(--primary-light);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.disable-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.disable-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
